import * as React from 'react';

import { Col, Row } from 'react-bootstrap';
import {
  faTrash,
  faSearch,
  faSquareFull,
  faPen,
  faMap,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RGBColor } from 'react-color';

import ColorPicker from '../modal/ColorPicker';
import CustomTooltip from '../common/CustomTooltip';

import {
  rgbTorgba,
  rgbTorgbObject,
  rgbObjectTorgb,
} from '../../util/featureStyle';

import {
  TOOLTIP_SUBSIDIARY_SHOW,
  TOOLTIP_SUBSIDIARY_EDIT,
  TOOLTIP_SUBSIDIARY_REMOVE,
  TOOLTIP_SUBSIDIARY_CHANGE_COLOR,
  TOOLTIP_SUBSIDIARY_DISTRIBUTION_TEMPLATES_SHOW,
  TOOLTIP_SUBSIDIARY_DISTRIBUTION_TEMPLATES_SAVE,
} from '../../constants/labels';

import { SubsidiaryListButtonsProps } from '../../@types/Subsidiary.d';

/**
 * Component to provide advanced user interaction for the subsidiary list.
 *
 * @param props
 */
const SubsidiaryListButtons: React.FC<SubsidiaryListButtonsProps> = (
  props: SubsidiaryListButtonsProps
) => {
  const {
    planningRestriction,
    subsidiary,
    showColorPicker,
    showPicker,
    showNewSubsidiaryDialog,
    changeSubsidiaryColor,
    deleteSubsidiary,
    zoomToSubsidiary,
    showSubsidiaryDistributionTemplates,
    showSubsidiaryDistributionTemplateModal,
  } = props;

  /**
   * Click event to start editing the subsidiary.
   *
   * @param event
   */
  const onClickEdit = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    showNewSubsidiaryDialog(subsidiary);
  };

  /**
   * Key down event to start editing the subsidiary.
   *
   * @param event
   */
  const onKeyDownEdit = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    if (event.keyCode === 13) showNewSubsidiaryDialog(subsidiary);
  };

  /**
   * Click event to set the maps position to the subsidiary.
   *
   * @param event
   */
  const onClickZoom = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    zoomToSubsidiary(subsidiary);
  };

  /**
   * Key down event to set the maps position to the subsidiary.
   *
   * @param event
   */
  const onKeyDownZoom = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();

    if (event.keyCode === 13) zoomToSubsidiary(subsidiary);
  };

  /**
   * Click event to delete the subsidiary.
   *
   * @param event
   */
  const onClickDelete = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    deleteSubsidiary(subsidiary);
  };

  /**
   * Key down to delete the subsidiary.
   *
   * @param event
   */
  const onKeyDownDelete = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();

    if (event.keyCode === 13) deleteSubsidiary(subsidiary);
  };

  /**
   * Click event to launch the color picker to select a new
   * color for the subsidiary.
   *
   * @param event
   */
  const onClickColor = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    showPicker();
  };

  /**
   * Key down event to launch the color picker to select a new
   * color for the subsidiary.
   *
   * @param event
   */
  const onKeyDownColor = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();

    if (event.keyCode === 13) showPicker();
  };

  /**
   * Click event to create a new subsidiary distribution template
   * from the current selection of the subsidiary.
   *
   * @param event
   */
  const onClickNewTemplate = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    showSubsidiaryDistributionTemplateModal(subsidiary);
  };

  /**
   * Key down event to create a new subsidiary distribution template
   * from the current selection of the subsidiary.
   *
   * @param event
   */
  const onKeyDownNewTemplate = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();

    if (event.keyCode === 13)
      showSubsidiaryDistributionTemplateModal(subsidiary);
  };

  /**
   * Click event to launch a modal dialog to show the subsidiarys distribution
   * templates.
   *
   * @param event
   */
  const onClickSubsidiaryDistributionTemplate = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    showSubsidiaryDistributionTemplates(true, subsidiary);
  };

  /**
   * Key down event to launch a modal dialog to show the subsidiarys distribution
   * templates.
   *
   * @param event
   */
  const onKeyDownSubsidiaryDistributionTemplate = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();

    if (event.keyCode === 13) showSubsidiaryDistributionTemplates(true);
  };

  /**
   * Callback to set the subsidiaries color.
   *
   * @param color
   */
  const acceptColorSelection = (color: RGBColor): void => {
    changeSubsidiaryColor(rgbObjectTorgb(color, 0.2));
  };

  return (
    <div>
      <Row className="no-gutters">
        <Col>
          <div className="separator" />
        </Col>
      </Row>
      <Row className="no-gutters justify-content-start">
        <Col xs={1} className="icon-col">
          <CustomTooltip
            tooltipId={`subsidiary-${subsidiary.id}-edit`}
            content={TOOLTIP_SUBSIDIARY_EDIT}
          >
            <div
              role="button"
              tabIndex={0}
              onClick={onClickEdit}
              onKeyDown={onKeyDownEdit}
              className="hover-icon text-center"
            >
              <FontAwesomeIcon icon={faPen} />
            </div>
          </CustomTooltip>
        </Col>
        <Col xs={1} className="icon-col">
          <CustomTooltip
            tooltipId={`subsidiary-${subsidiary.id}-zoom`}
            content={TOOLTIP_SUBSIDIARY_SHOW}
          >
            <div
              role="button"
              tabIndex={0}
              onClick={onClickZoom}
              onKeyDown={onKeyDownZoom}
              className="hover-icon text-center"
            >
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </CustomTooltip>
        </Col>
        {planningRestriction === 'NONE' && (
          <Col xs={1} className="icon-col">
            <CustomTooltip
              tooltipId={`subsidiary-${subsidiary.id}-rem`}
              content={TOOLTIP_SUBSIDIARY_REMOVE}
            >
              <div
                role="button"
                tabIndex={0}
                onClick={onClickDelete}
                onKeyDown={onKeyDownDelete}
                className="hover-icon text-center"
              >
                <FontAwesomeIcon icon={faTrash} />
              </div>
            </CustomTooltip>
          </Col>
        )}
        <Col xs={1} className="icon-col">
          <CustomTooltip
            tooltipId={`subsidiary-${subsidiary.id}-dist-show`}
            content={TOOLTIP_SUBSIDIARY_DISTRIBUTION_TEMPLATES_SHOW}
          >
            <div
              role="button"
              tabIndex={0}
              onClick={onClickSubsidiaryDistributionTemplate}
              onKeyDown={onKeyDownSubsidiaryDistributionTemplate}
              className="hover-icon text-center"
            >
              <FontAwesomeIcon icon={faMap} />
            </div>
          </CustomTooltip>
        </Col>
        <Col
          role="button"
          tabIndex={0}
          onClick={onClickColor}
          onKeyDown={onKeyDownColor}
          xs={1}
          className="icon-col"
        >
          <CustomTooltip
            tooltipId={`subsidiary-${subsidiary.id}-color`}
            content={TOOLTIP_SUBSIDIARY_CHANGE_COLOR}
          >
            <div className="text-center">
              <FontAwesomeIcon
                icon={faSquareFull}
                color={rgbTorgba(subsidiary.colorSelectedFill)}
              />
              {showColorPicker ? (
                <ColorPicker
                  id={`${subsidiary.id}`}
                  color={rgbTorgbObject(subsidiary.colorSelectedFill)}
                  accept={acceptColorSelection}
                  abort={showPicker}
                />
              ) : null}
            </div>
          </CustomTooltip>
        </Col>
        <Col className="icon-col" />
        <Col xs={1} className="icon-col">
          <CustomTooltip
            tooltipId={`subsidiary-${subsidiary.id}-save`}
            content={TOOLTIP_SUBSIDIARY_DISTRIBUTION_TEMPLATES_SAVE}
            placement="left-start"
          >
            <div
              role="button"
              tabIndex={0}
              onClick={onClickNewTemplate}
              onKeyDown={onKeyDownNewTemplate}
              className="hover-icon text-center"
            >
              <FontAwesomeIcon icon={faSave} />
            </div>
          </CustomTooltip>
        </Col>
      </Row>
    </div>
  );
};

export default SubsidiaryListButtons;
