import * as React from 'react';

import { Button, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faCrosshairs,
} from '@fortawesome/free-solid-svg-icons';

import AddressSearchField from './AddressSearchField';

import config from '../../config';
import {
  MAP_BUTTON_EXPORT,
  MAP_BUTTON_IMPORT,
  MAP_BUTTON_PRINT,
  MAP_BUTTON_WEEKPART_WEEKEND,
  MAP_BUTTON_WEEKPART_MIDWEEK,
  MAP_BUTTON_SUBSIDIARIES_HIDE,
  MAP_BUTTON_SUBSIDIARIES_SHOW,
  MAP_BUTTON_PERIMETER,
  MAP_BUTTON_REVERT_PERIMETER,
  EXPORT_TYPE_CSV,
  EXPORT_TYPE_EXCEL,
  MAP_BUTTON_DISTRIBUTION_TEMPLATES_HIDE,
  MAP_BUTTON_DISTRIBUTION_TEMPLATES_SHOW,
  MAP_BUTTON_ORDER_HISTORY_HIDE,
  MAP_BUTTON_ORDER_HISTORY_SHOW,
  MAP_BUTTON_WEEKPART_BEST,
  MAP_BUTTON_ISOCHRONE,
} from '../../constants/labels';
import {
  WEEKPART_WEEKEND,
  TRANSMISSION_TYPE_OFFER,
  WEEKPART_MIDWEEK,
} from '../../constants/constants';

import {
  CenterLocationButtonProps,
  ExportButtonProps,
  FitSelectionButtonProps,
  ImportButtonProps,
  PrintSelectionButtonProps,
  SubsidiariesButtonProps,
  WeekpartToggleProps,
  DistributionTemplatesButtonProps,
  MapMenuProps,
  MapMenuState,
  HistoryButtonProps,
  IsochroneButtonProps,
  PerimeterButtonProps,
  RevertPerimeterButtonProps,
} from '../../@types/MapButtons.d';

/**
 * Button to provide export functionality for the selection
 * either as excel or csv file
 *
 * @param props
 */
const ExportButton: React.FC<ExportButtonProps> = (
  props: ExportButtonProps
) => {
  const { exportExcel, exportCSV } = props;

  /**
   * Click action to export current selectiom as csv file
   * @param event
   */
  const onClickExportCSV = (event: any): void => {
    event.stopPropagation();

    exportCSV();
  };

  /**
   * Click action to export current selectiom as excel file
   * @param event
   */
  const onClickExportExcel = (event: any): void => {
    event.stopPropagation();

    exportExcel();
  };

  return (
    <div className="underlay-background">
      <Dropdown>
        <Dropdown.Toggle id="export-dropdown" className="map-menu-button big">
          {MAP_BUTTON_EXPORT}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={onClickExportCSV}>
            {EXPORT_TYPE_CSV}
          </Dropdown.Item>
          <Dropdown.Item onClick={onClickExportExcel}>
            {EXPORT_TYPE_EXCEL}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

/**
 * Button to fit the map to the currently selected areas.
 *
 * @param props
 */
export const FitSelectionButton: React.FC<FitSelectionButtonProps> = (
  props: FitSelectionButtonProps
) => {
  const { fitSelection } = props;

  /**
   * Click action to trigger the fit process
   *
   * @param event
   */
  const onClickFitButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    fitSelection();
  };
  return (
    <Button
      type="button"
      className="map-menu-button small"
      onClick={onClickFitButton}
    >
      <FontAwesomeIcon icon={faCrosshairs} />
    </Button>
  );
};

/**
 * Button to revert the most previously made perimeter selection.
 * The button is only visible for about 20 seconds after making
 * a perimeter selection.
 *
 * @param props
 */
export const RevertPerimeterButton: React.FC<RevertPerimeterButtonProps> = (
  props: RevertPerimeterButtonProps
) => {
  const { revertPerimeter } = props;

  /**
   * Click action to revert the previsously made perimeter selection.
   *
   * @param event
   */
  const onClickRevertPerimeterButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    revertPerimeter();
  };
  return (
    <Button
      type="button"
      className="map-menu-button big"
      onClick={onClickRevertPerimeterButton}
    >
      {MAP_BUTTON_REVERT_PERIMETER}
    </Button>
  );
};

/**
 * Button to show an modal which provides functionality
 * to import multiple areas via text area.
 *
 * @param props
 */
const ImportButton: React.FC<ImportButtonProps> = (
  props: ImportButtonProps
) => {
  const { showImportModal } = props;

  /**
   * Click action to launch the import modal.
   *
   * @param event
   */
  const onClickImport = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();
    showImportModal(true);
  };

  return (
    <div className="underlay-background">
      <Button
        type="button"
        className="map-menu-button big"
        onClick={onClickImport}
      >
        {MAP_BUTTON_IMPORT}
      </Button>
    </div>
  );
};

/**
 * Button to export the current selection as a pdf file which
 * includes an image of the current state of the selection.
 *
 * @param props
 */
const PrintSelectionButton: React.FC<PrintSelectionButtonProps> = (
  props: PrintSelectionButtonProps
) => {
  const { printSelection } = props;

  /**
   * Click action to trigger the generation of the pdf file.
   *
   * @param event
   */
  const onClickPrint = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    printSelection();
  };

  return (
    <div className="underlay-background">
      <Button
        type="button"
        className="map-menu-button big"
        onClick={onClickPrint}
      >
        {MAP_BUTTON_PRINT}
      </Button>
    </div>
  );
};

/**
 * Button to toggle the visibility of the subsidiary list.
 *
 * @param props
 */
const SubsidiariesButton: React.FC<SubsidiariesButtonProps> = (
  props: SubsidiariesButtonProps
) => {
  const { toggleSubsidiaries, showSubsidiaryList } = props;

  /**
   * Click action to toggle the visibility of the subsidiary list.
   *
   * @param event
   */
  const onClickShow = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();
    toggleSubsidiaries();
  };

  return (
    <div className="underlay-background">
      <Button
        type="button"
        className="map-menu-button big"
        onClick={onClickShow}
      >
        {showSubsidiaryList
          ? MAP_BUTTON_SUBSIDIARIES_HIDE
          : MAP_BUTTON_SUBSIDIARIES_SHOW}
      </Button>
    </div>
  );
};

/**
 * Button to switch through the weekparts avaiable to the client.
 * This button is only visible if more than one weekpart is
 * available.
 *
 * @param props
 */
const WeekpartToggleButton: React.FC<WeekpartToggleProps> = (
  props: WeekpartToggleProps
) => {
  const { weekpart, toggleWeekpart } = props;

  // Determine the next weekpart.
  let weekpartLabel;
  switch (weekpart) {
    case WEEKPART_WEEKEND:
      weekpartLabel = MAP_BUTTON_WEEKPART_WEEKEND;
      break;
    case WEEKPART_MIDWEEK:
      weekpartLabel = MAP_BUTTON_WEEKPART_MIDWEEK;
      break;
    default:
      weekpartLabel = MAP_BUTTON_WEEKPART_BEST;
  }

  /**
   * Click action to switch the selected weekpart.
   *
   * @param event
   */
  const onClickWeekpartToggle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    toggleWeekpart();
  };

  return (
    <div className="underlay-background">
      <button
        type="button"
        className="map-menu-button big"
        onClick={onClickWeekpartToggle}
      >
        {weekpartLabel}
      </button>
    </div>
  );
};

/**
 * Button to center the map on the current position of the user.
 * This will only be as accurate as the browser can determine it.
 *
 * @param props
 */
const CenterCurrentLocationButton: React.FC<CenterLocationButtonProps> = (
  props: CenterLocationButtonProps
) => {
  const { centerLocation } = props;

  /**
   * Click action to trigger the centering process.
   *
   * @param event
   */
  const onClickCenter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    centerLocation();
  };

  return (
    <div className="underlay-background center-container">
      <Button
        role="button"
        className="map-menu-button small center-button"
        onClick={onClickCenter}
      >
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </Button>
    </div>
  );
};

/**
 * Button to toggle the visibility of the clients available list of distribution templates.
 *
 * @param props
 */
const DistributionTemplateButton: React.FC<DistributionTemplatesButtonProps> = (
  props: DistributionTemplatesButtonProps
) => {
  const { toggleDistributionTemplates, showDistributionTemplates } = props;

  /**
   * Click action to toggle the visibility of the distributiontemplate list.
   * @param event
   */
  const onClickShow = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();
    toggleDistributionTemplates();
  };

  return (
    <div className="underlay-background">
      <button
        type="button"
        className="map-menu-button big"
        onClick={onClickShow}
      >
        {showDistributionTemplates
          ? MAP_BUTTON_DISTRIBUTION_TEMPLATES_HIDE
          : MAP_BUTTON_DISTRIBUTION_TEMPLATES_SHOW}
      </button>
    </div>
  );
};

/**
 * Button to toggle the visibility of the clients previsouly made order or received offers.
 *
 * @param props
 */
const HistoryButton: React.FC<HistoryButtonProps> = (
  props: HistoryButtonProps
) => {
  const { toggleHistory, showHistory, transmissionType } = props;

  /**
   * Click action to toggle the visibility of the list of previsouly made order or
   * received offers.
   *
   * @param event
   */
  const onClickShow = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();
    toggleHistory();
  };

  return (
    <div className="underlay-background">
      <button
        type="button"
        className="map-menu-button big"
        onClick={onClickShow}
      >
        {showHistory
          ? MAP_BUTTON_ORDER_HISTORY_HIDE(
              transmissionType === TRANSMISSION_TYPE_OFFER
            )
          : MAP_BUTTON_ORDER_HISTORY_SHOW(
              transmissionType === TRANSMISSION_TYPE_OFFER
            )}
      </button>
    </div>
  );
};

/**
 * Button to show the modal for dynamic/isochrone planing.
 *
 * @param props
 */
const IsochroneButton: React.FC<IsochroneButtonProps> = (
  props: IsochroneButtonProps
) => {
  const { showIsochroneModal } = props;

  /**
   * Click action to bring up the modal for dynamic/isochrone planing.
   * @param event
   */
  const onClickShow = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    showIsochroneModal(true);
  };

  return (
    <div className="underlay-background">
      <button
        type="button"
        className="map-menu-button big"
        onClick={onClickShow}
      >
        {MAP_BUTTON_ISOCHRONE}
      </button>
    </div>
  );
};

/**
 * Button to start/stop the perimeter selection.
 *
 * @param props
 */
const PerimeterButton: React.FC<PerimeterButtonProps> = (
  props: PerimeterButtonProps
) => {
  const { drawPerimeter } = props;

  /**
   * Click action to start/stop the perimeter selection.
   * @param event
   */
  const onClickPerimeter = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.stopPropagation();
    event.preventDefault();

    drawPerimeter();
  };

  return (
    <div className="underlay-background">
      <button
        type="button"
        className="map-menu-button big"
        onClick={onClickPerimeter}
      >
        {MAP_BUTTON_PERIMETER}
      </button>
    </div>
  );
};

/**
 * Container component to hold the map buttons and arrange them
 * accordingly.
 * The buttons can each be disabled via the config file/.env files.
 */
export default class MapMenu extends React.Component<
  MapMenuProps,
  MapMenuState
> {
  constructor(props: MapMenuProps) {
    super(props);

    this.state = {};
  }

  render(): JSX.Element {
    const {
      sideMenuExpanded,
      weekpart,
      transmissionType,
      centerLocation,
      fitSelection,
      weekparts,
      showImportModal,
      showSubsidiaryList,
      showDistributionTemplateList,
      showHistoryList,
      toggleWeekpart,
      toggleSubsidiaries,
      toggleDistributionTemplates,
      toggleHistory,
      exportExcel,
      exportCSV,
      printSelection,
      subsidiaryMode,
      zoomToAddress,
      showIsochroneModal,
      drawPerimeter,
      showRevertPerimeterButton,
      revertPerimeter,
      planningRestriction,
    } = this.props;

    return (
      <>
        {config.map.buttons.fitSelection && (
          <div className="fit-selection-container ol-control ol-unselectable">
            <FitSelectionButton fitSelection={fitSelection} />
          </div>
        )}
        {config.map.buttons.drawPerimeter && showRevertPerimeterButton && (
          <div className="revert-perimeter-container ol-control ol-unselectable">
            <RevertPerimeterButton revertPerimeter={revertPerimeter} />
          </div>
        )}
        <div className="map-menu">
          <div className="half-container">
            <div
              className={`left  ${
                sideMenuExpanded ? 'side-menu-expanded' : 'side-menu-collapsed'
              }`}
            >
              {config.map.buttons.printMap && (
                <PrintSelectionButton printSelection={printSelection} />
              )}
              {config.map.buttons.importPLZ &&
                planningRestriction === 'NONE' && (
                  <ImportButton showImportModal={showImportModal} />
                )}
              {config.map.buttons.selectWeekpart && weekparts.length > 1 && (
                <WeekpartToggleButton
                  toggleWeekpart={toggleWeekpart}
                  weekpart={weekpart}
                />
              )}
              {config.map.buttons.showSubsidiaries &&
                subsidiaryMode && [
                  <SubsidiariesButton
                    key={1}
                    showSubsidiaryList={showSubsidiaryList}
                    toggleSubsidiaries={toggleSubsidiaries}
                  />,
                  <DistributionTemplateButton
                    key={2}
                    showDistributionTemplates={showDistributionTemplateList}
                    toggleDistributionTemplates={toggleDistributionTemplates}
                  />,
                  <HistoryButton
                    key={3}
                    transmissionType={transmissionType}
                    showHistory={showHistoryList}
                    toggleHistory={toggleHistory}
                  />,
                ]}
              {config.map.buttons.exportSelection && subsidiaryMode && (
                <ExportButton exportExcel={exportExcel} exportCSV={exportCSV} />
              )}
              {config.map.buttons.isochrone && subsidiaryMode && (
                <IsochroneButton showIsochroneModal={showIsochroneModal} />
              )}
              {config.map.buttons.drawPerimeter &&
                planningRestriction === 'NONE' && (
                  <PerimeterButton drawPerimeter={drawPerimeter} />
                )}
            </div>
          </div>
          <div className="spacer" />
          <div className="half-container">
            <div className="right">
              {config.map.buttons.searchAddress && (
                <AddressSearchField zoomToAddress={zoomToAddress} />
              )}
              {config.map.buttons.zoomToCurrentLocation &&
                config.map.buttons.searchAddress && (
                  <CenterCurrentLocationButton
                    centerLocation={centerLocation}
                  />
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
