import * as React from 'react';

import { Modal, Button, Col, Form } from 'react-bootstrap';
import Select from 'react-select';

import {
  BUTTON_TITLE_CONFIRM,
  BUTTON_TITLE_ABORT,
  MODAL_TITLE_PRINT_MAP,
  MODAL_PRINT_MAP_PAPER_SIZE,
  MODAL_PRINT_MAP_RESOLUTION,
} from '../../constants/labels';
import {
  PAPER_SIZES,
  selectPickerTheme,
  RESOLUTIONS,
} from '../../constants/constants';

import { PrintModalProps, PrintModalState } from '../../@types/Modal.d';

/**
 * Modal dialog to select the parameters for the map print export
 *
 * @param props
 */
export default class PrintMapModal extends React.Component<
  PrintModalProps,
  PrintModalState
> {
  constructor(props: PrintModalProps) {
    super(props);

    this.state = {
      selectedPaperSize: 'A4',
      selectedResolution: RESOLUTIONS[1],
    };

    this.onHide = this.onHide.bind(this);
    this.onClickConfirm = this.onClickConfirm.bind(this);
    this.onChangePaperSize = this.onChangePaperSize.bind(this);
    this.onChangeResolution = this.onChangeResolution.bind(this);
  }

  /**
   * Hide the modal dialog.
   */
  onHide(): void {
    const { showModal } = this.props;
    showModal();
  }

  /**
   * Click action to print the map.
   *
   * @param event
   */
  onClickConfirm(event: React.MouseEvent): void {
    const { printMap } = this.props;
    const { selectedPaperSize, selectedResolution } = this.state;

    printMap(selectedPaperSize, selectedResolution.value);

    this.onHide();
    event.preventDefault();
  }

  onChangePaperSize(paperSizeSelection: any): void {
    if (paperSizeSelection && paperSizeSelection !== null)
      this.setState({ selectedPaperSize: paperSizeSelection.value });
  }

  onChangeResolution(resolutionSelection: any): void {
    if (resolutionSelection && resolutionSelection !== null)
      this.setState({
        selectedResolution: {
          value: resolutionSelection.value,
          label: resolutionSelection.label,
        },
      });
  }

  render(): JSX.Element {
    const { show, container } = this.props;
    const { selectedPaperSize, selectedResolution } = this.state;
    return (
      <Modal
        show={show}
        onHide={this.onHide}
        container={container.current}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{MODAL_TITLE_PRINT_MAP}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Col xs={6}>
              <Form.Label>{MODAL_PRINT_MAP_PAPER_SIZE}</Form.Label>
              <Select
                blurInputOnSelect
                isSearchable={false}
                options={PAPER_SIZES.map(paperSize => ({
                  value: paperSize.name,
                  label: paperSize.name,
                }))}
                value={{
                  value: selectedPaperSize,
                  label: selectedPaperSize,
                }}
                placeholder={MODAL_PRINT_MAP_PAPER_SIZE}
                onChange={this.onChangePaperSize}
                theme={(theme: any) => selectPickerTheme(theme)}
              />
            </Col>
            <Col xs={6}>
              <Form.Label>{MODAL_PRINT_MAP_RESOLUTION}</Form.Label>
              <Select
                blurInputOnSelect
                isSearchable={false}
                options={RESOLUTIONS}
                value={selectedResolution}
                placeholder={MODAL_PRINT_MAP_RESOLUTION}
                onChange={this.onChangeResolution}
                theme={(theme: any) => selectPickerTheme(theme)}
              />
            </Col>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Col className="p-0">
            <Button
              className="ci modal-footer-button"
              onClick={this.onClickConfirm}
            >
              {BUTTON_TITLE_CONFIRM}
            </Button>
          </Col>
          <Col className="p-0">
            <Button className="ci modal-footer-button" onClick={this.onHide}>
              {BUTTON_TITLE_ABORT}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
