/* eslint-disable react/jsx-indent */
import '../../styles/SubsidiaryList.scss';

import * as React from 'react';

import SubsidiaryList from './SubsidiaryList';
import ListHeader from '../common/listHeader/ListHeader';

import {
  SUBSIDIARY_LIST_SEARCH_FIELD_HINT,
  SUBSIDIARY_LIST_TITLE,
} from '../../constants/labels';

import {
  SubsidiaryListContainerProps,
  SubsidiaryListContainerState,
} from '../../@types/Subsidiary.d';

/**
 * Container component to hold a list of subsidiries.
 */
export default class SubsidiaryListContainer extends React.Component<
  SubsidiaryListContainerProps,
  SubsidiaryListContainerState
> {
  constructor(props: SubsidiaryListContainerProps) {
    super(props);

    const { subsidiaries } = this.props;
    this.state = {
      filteredSubsidiaries: subsidiaries,
      searchString: '',
    };

    this.filterSubsidiaryList = this.filterSubsidiaryList.bind(this);
  }

  /**
   * Used to detect changes in the subsidiary list and possibly refresh
   * the list.
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps: SubsidiaryListContainerProps): void {
    const { subsidiaries } = this.props;

    if (
      JSON.stringify(
        subsidiaries.map(sub => ({
          ...sub,
          ...{ areas: [] },
          ...{ feature: undefined },
          ...{ layer: undefined },
        }))
      ) !==
      JSON.stringify(
        prevProps.subsidiaries.map(sub => ({
          ...sub,
          ...{ areas: [] },
          ...{ feature: undefined },
          ...{ layer: undefined },
        }))
      )
    ) {
      const { searchString } = this.state;
      this.filterSubsidiaryList(searchString);
    }
  }

  /**
   * Filter the subsidiary list by a given search string.
   *
   * @param searchString
   */
  filterSubsidiaryList(searchString: string): void {
    const { subsidiaries } = this.props;
    let filteredSubsidiaries;
    if (searchString.length <= 0) filteredSubsidiaries = subsidiaries;
    else {
      const searchStringRegex = searchString
        .split('')
        .map(s => `[${s}]\\w*`)
        .join('');
      const regex = new RegExp(`\\w*${searchStringRegex}`, 'gi');
      filteredSubsidiaries = subsidiaries.filter(({ name }) =>
        name.match(regex)
      );
    }

    this.setState({ filteredSubsidiaries, searchString });
  }

  render(): JSX.Element {
    const {
      planningRestriction,
      showSubsidiaryList,
      hideSubsidiaryList,
      showNewSubsidiaryDialog,
      deleteSubsidiary,
      changeSubsidiaryColor,
      zoomToSubsidiary,
      setSubsidiarySelected,
      showSubsidiaryDistributionTemplates,
      showSubsidiaryDistributionTemplateModal,
    } = this.props;
    const { filteredSubsidiaries, searchString } = this.state;

    return (
      <div
        className={`collapse-content d-flex flex-column ${
          showSubsidiaryList ? 'shown' : 'hidden'
        } width subsidiary-list-container`}
      >
        {showSubsidiaryList
          ? [
              <ListHeader
                key={0}
                hideList={hideSubsidiaryList}
                searchString={searchString}
                filterList={this.filterSubsidiaryList}
                listTitle={SUBSIDIARY_LIST_TITLE}
                searchHint={SUBSIDIARY_LIST_SEARCH_FIELD_HINT}
              />,
              <SubsidiaryList
                key={1}
                planningRestriction={planningRestriction}
                subsidiaries={filteredSubsidiaries}
                showNewSubsidiaryDialog={showNewSubsidiaryDialog}
                deleteSubsidiary={deleteSubsidiary}
                changeSubsidiaryColor={changeSubsidiaryColor}
                zoomToSubsidiary={zoomToSubsidiary}
                setSubsidiarySelected={setSubsidiarySelected}
                showSubsidiaryDistributionTemplates={
                  showSubsidiaryDistributionTemplates
                }
                showSubsidiaryDistributionTemplateModal={
                  showSubsidiaryDistributionTemplateModal
                }
              />,
            ]
          : null}
      </div>
    );
  }
}
