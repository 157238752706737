import * as React from 'react';

import { ListGroup } from 'react-bootstrap';

import SubsidiaryListItem from './SubsidiaryListItem';
import ListAddItem from '../common/ListAddItem';

import { sortClientLocations } from '../../util/sortUtil';
import {
  MODAL_TITLE_ADD_SUBSIDIARY,
  SUBSIDIARY_DISTRIBUTION_TEMPLATE_LIST_EMPTY,
} from '../../constants/labels';

import { SubsidiaryListProps } from '../../@types/Subsidiary.d';

/**
 * Component to display a list of subsidiaries.
 *
 * @param props
 */
const SubsidiaryList: React.FC<SubsidiaryListProps> = (
  props: SubsidiaryListProps
) => {
  const {
    planningRestriction,
    subsidiaries,
    showNewSubsidiaryDialog,
    deleteSubsidiary,
    changeSubsidiaryColor,
    zoomToSubsidiary,
    setSubsidiarySelected,
    showSubsidiaryDistributionTemplates,
    showSubsidiaryDistributionTemplateModal,
  } = props;

  return (
    <div className="list-container">
      <ListGroup className="flex-list">
        {subsidiaries.length > 0 ? (
          sortClientLocations(subsidiaries).map(subsidiary => (
            <SubsidiaryListItem
              key={subsidiary.id}
              planningRestriction={planningRestriction}
              subsidiary={subsidiary}
              showNewSubsidiaryDialog={showNewSubsidiaryDialog}
              deleteSubsidiary={deleteSubsidiary}
              changeSubsidiaryColor={changeSubsidiaryColor}
              zoomToSubsidiary={zoomToSubsidiary}
              setSubsidiarySelected={setSubsidiarySelected}
              showSubsidiaryDistributionTemplates={
                showSubsidiaryDistributionTemplates
              }
              showSubsidiaryDistributionTemplateModal={
                showSubsidiaryDistributionTemplateModal
              }
            />
          ))
        ) : (
          <ListGroup.Item>
            {SUBSIDIARY_DISTRIBUTION_TEMPLATE_LIST_EMPTY}
          </ListGroup.Item>
        )}
      </ListGroup>
      {planningRestriction === 'NONE' && (
        <ListAddItem
          showNewModal={showNewSubsidiaryDialog}
          title={MODAL_TITLE_ADD_SUBSIDIARY}
        />
      )}
    </div>
  );
};

export default SubsidiaryList;
