/**
 * This file holds labels and texts which are used accross
 * the project. The reason is that all constants are held
 * in one place and therefore can be found easier.
 */

import { DistributionType } from '../@types/Area.d';
import { Price } from '../@types/Common';

export const MAP_BUTTON_CENTER = 'Zentrieren';
export const MAP_BUTTON_EXPORT = 'Export';
export const MAP_BUTTON_FIT = 'Auswahl zentrieren';
export const MAP_BUTTON_IMPORT = 'Import';
export const MAP_BUTTON_PRINT = 'Karte drucken';
export const MAP_BUTTON_SUBSIDIARIES_SHOW = 'Filialen';
export const MAP_BUTTON_SUBSIDIARIES_HIDE = 'Filialen';
export const MAP_BUTTON_DISTRIBUTION_TEMPLATES_SHOW = 'Vorlagen';
export const MAP_BUTTON_DISTRIBUTION_TEMPLATES_HIDE = 'Vorlagen';
export const MAP_BUTTON_ORDER_HISTORY_SHOW = (offer: boolean): string =>
  `${offer ? 'Angebote' : 'Aufträge'}`;
export const MAP_BUTTON_ORDER_HISTORY_HIDE = (offer: boolean): string =>
  `${offer ? 'Angebote' : 'Aufträge'}`;
export const MAP_BUTTON_WEEKPART_WEEKEND = 'Wochenende';
export const MAP_BUTTON_WEEKPART_MIDWEEK = 'Wochenmitte';
export const MAP_BUTTON_WEEKPART_BEST = 'Optimal';
export const MAP_BUTTON_SEARCH_PLACEHOLDER = 'Suchbegriff eingeben...';
export const MAP_BUTTON_ISOCHRONE = 'Fahrtzeit';
export const MAP_BUTTON_PERIMETER = 'Umkreis';
export const MAP_BUTTON_REVERT_PERIMETER = 'Umkreis rückg.';

export const MAP_OVERLAY_CLIENT_LOCATION_NAME = 'Filiale';
export const MAP_OVERLAY_CLIENT_LOCATION_STREET = 'Straße';
export const MAP_OVERLAY_CLIENT_LOCATION_POSTCODE = 'Plz';
export const MAP_OVERLAY_CLIENT_LOCATION_PLACE = 'Name';

export const MAP_OVERLAY_AREA_NAME = 'Name';
export const MAP_OVERLAY_POSTCODE = 'PLZ';

export const EXPORT_TYPE_CSV = 'CSV';
export const EXPORT_TYPE_EXCEL = 'Excel (.xlsx)';

export const SUBSIDIARY_DISTRIBUTION_TEMPLATE_LIST_EMPTY =
  'Keine Filial-Verteilvorlage vorhanden';
export const DISTRIBUTION_TEMPLATE_LIST_EMPTY =
  'Keine Verteilvorlage vorhanden';
export const LOCALITIES_LIST_EMPTY = 'Keine Ortsteile vorhanden';
export const HISTORY_LIST_EMPTY = (offer: boolean): string =>
  `Keine vergangenen ${offer ? 'Angebote' : 'Aufträge'} vorhanden`;
export const SUBSIDIARY_LIST_EMPTY = 'Keine Filialen vorhanden';

export const MODAL_TITLE_IMPORT = 'Postleitzahlen importieren';
export const MODAL_TITLE_ADD_DISTRIBUTION_TEMPLATE =
  'Aktuelle Auswahl als Vorlage speichern';
export const MODAL_TITLE_ADD_SUBSIDIARY = 'Filiale hinzufügen';
export const MODAL_TITLE_EDIT_SUBSIDIARY = 'Filiale bearbeiten';
export const MODAL_TITLE_LOCALITIES = (
  areaName: string,
  areaKey: string
): string => `Orsteile von ${areaKey}, ${areaName} an-/abwählen`;
export const MODAL_TITLE_SUBSIDIARY_DISTRIBUTION_TEMPLATES = (
  subsidiaryName?: string
): string => `Vereteilvorlagen von ${subsidiaryName ?? 'Filiale'} `;
export const MODAL_TITLE_ISOCHRONE = 'Fahrtzeitplanung';
export const MODAL_TITLE_PRINT_MAP = 'Karte drucken';

export const MODAL_IMPORT_APPEND_AREAS = 'Zu Auswahl hinzufügen';
export const MODAL_IMPORT_KEYLIST_PLACEHOLDER =
  'Gewünschte Postleitzahlen hier eingeben.';
export const MODAL_IMPORT_KEYLIST_INVALID =
  'Es muss mindestens eine Postleitzahl angegeben werden.';

export const MODAL_ISOCHRONE_CONTENT =
  'Die angegebene Fahrtzeit darf nicht mehr als 60 Minuten betragen.';
export const MODAL_ISOCHRONE_RANGE_LABEL = 'Fahrtzeit';
export const MODAL_ISOCHRONE_RANGE_LABEL_MINUTES = 'Minuten';
export const MODAL_ISOCHRONE_RANGE_PLACEHOLDER = 'Fahrzeit in Minuten';
export const MODAL_ISOCHRONE_RANGE_INVALID_EMPTY =
  'Es muss eine Fahrtzeit angegeben werden.';
export const MODAL_ISOCHRONE_RANGE_INVALID_VALUE_TOO_HIGH =
  'Die Fahrzeit darf 60 Minuten nicht übersteigen.';
export const MODAL_ISOCHRONE_RANGE_INVALID_VALUE_INVALID =
  'Es muss eine gültige Zahl größer 0 eingegeben werden.';
export const MODAL_ISOCHRONE_PROFILE_CAR = 'Auto';
export const MODAL_ISOCHRONE_PROFILE_BIKE = 'Fahrrad';
export const MODAL_ISOCHRONE_PROFILE_WALK = 'Zu Fuß';
export const MODAL_ISOCHRONE_BUDGET_LABEL = 'Budget';
export const MODAL_ISOCHRONE_BUDGET_PLACEHOLDER = 'Budget';
export const MODAL_ISOCHRONE_BUDGET_CURRENCY = '€';
export const MODAL_ISOCHRONE_SCORE_LABEL = 'Score';
export const MODAL_ISOCHRONE_SCORE_PLACEHOLDER = 'Score';

export const MODAL_PRINT_MAP_PAPER_SIZE = 'Format';
export const MODAL_PRINT_MAP_RESOLUTION = 'Qualität';

export const BUTTON_TITLE_CREATE = 'Erstellen';
export const BUTTON_TITLE_CONFIRM = 'Bestätigen';
export const BUTTON_TITLE_ACCEPT = 'Übernehmen';
export const BUTTON_TITLE_ABORT = 'Abbrechen';
export const BUTTON_TITLE_IMPORT = 'Importieren';
export const BUTTON_TITLE_SUBMIT = 'Abschicken';
export const BUTTON_TITLE_ADVANCED = 'Erweitert';
export const BUTTON_TITLE_SIMPLE = 'Einfach';

export const CIRCULATION_PIECES_SHORT = 'Stk.';
export const CIRCULATION_PIECES_LONG = 'Stück';
export const CIRCULATION_TITLE_WEEKEND_SHORT = 'WE';
export const CIRCULATION_TITLE_WEEKEND_LONG = 'Verteilung Wochenende';
export const CIRCULATION_TITLE_MIDWEEK_SHORT = 'WM';
export const CIRCULATION_TITLE_MIDWEEK_LONG = 'Verteilung Wochenmitte';
export const CIRCULATION_TITLE_BEST_SHORT = 'Optimal';
export const CIRCULATION_TITLE_BEST_LONG = 'Verteilung Optimal';
export const CIRCULATION_NO_DISTRIBUTION = 'Keine Verteilung';
export const CIRCULATION_INCLUDED = 'enthalten';

export const PRICE_CURRENCY_SHORT = '€';
export const PRICE_CURRENCY_SHORTER = 'EUR';
export const PRICE_CURRENCY = 'Euro';
export const PRICE_LABEL = 'Gesamtpreis:';

export const ADDITIONAL_AREA_TOLLTIP = (
  areaName: string,
  areaKey: string
): string => `Tourenabhängigkeit von ${areaKey}, ${areaName}.`;

export const LOCALITIES_TOOLTIP =
  'Es wurden ein oder merhere Ortsteile abgewählt.';

export const LOADING_PLEASE_WAIT = 'Bitte warten';
export const LOADING_PROCESS_REQUEST = 'Ihre Anfrage wird bearbeitet ...';
export const LOADING_LAYERS = (
  loadedLayers: number,
  totalLayers: number
): string => `${loadedLayers} von ${totalLayers} geladen.`;

export const SUBSIDIARY_LIST_TITLE: string = 'Filialen';
export const SUBSIDIARY_LIST_NEW_SUBSIDIARY = 'Filiale hinzufügen';
export const SUBSIDIARY_LIST_SEARCH_FIELD_HINT = 'Filiale suchen ...';

export const DISTRIBUTION_TEMPLATE_LIST_TITLE: string = 'Verteilvorlagen';
export const DISTRIBUTION_TEMPLATE_LIST_NEW_TEMPLATE =
  'Verteilvorlage hinzufügen';
export const DISTRIBUTION_TEMPLATE_LIST_SEARCH_FIELD_HINT =
  'Verteilvorlage suchen ...';

export const HISTORY_TEMPLATE_LIST_TITLE = (offer: boolean): string =>
  `${offer ? 'Angebote' : 'Aufträge'}`;
export const HISTORY_TEMPLATE_LIST_SEARCH_FIELD_HINT = (
  offer: boolean
): string => `${offer ? 'Angeots' : 'Auftrags'}historie suchen ...`;
export const HISTORY_TEMPLATE_LIST_ORDER_ID = (
  offer: boolean,
  number: number
): string =>
  `${offer ? 'Angeots' : 'Auftrags'}nummer: ${`000000${number}`.slice(-6)}`;
export const HISTORY_TEMPLATE_LIST_ORDER_LABEL = (date: string): string =>
  `Verteilung vom ${date}`;
export const HISTORY_TEMPLATE_LIST_ORDER_DISTRIBUTION_DATE = (
  date: string
): string => `Verteiltag: ${date}`;

export const DISTRIBUTION_TEMPLATE_MODAL_NAME_TITLE = 'Name der Vorlage';
export const DISTRIBUTION_TEMPLATE_MODAL_NAME_PLACEHOLDER = 'Name der Vorlage';
export const DISTRIBUTION_TEMPLATE_MODAL_NAME_INVALID =
  'Das Feld "Name der Vorlage" muss ausgefüllt werden.';

export const SUBSIDIARY_MODAL_NAME_TITLE = 'Filialname';
export const SUBSIDIARY_MODAL_NAME_PLACEHOLDER = 'Filialname';
export const SUBSIDIARY_MODAL_NAME_INVALID =
  'Das Feld "Filialname" muss ausgefüllt werden.';
export const SUBSIDIARY_MODAL_ADDRESS_NAME_TITLE = 'Anschrift';
export const SUBSIDIARY_MODAL_ADDRESS_NAME_PLACEHOLDER = 'Anschrift';
export const SUBSIDIARY_MODAL_ADDRESS_NAME_INVALID =
  'Das Feld "Anschrift" muss ausgefüllt werden.';
export const SUBSIDIARY_MODAL_NUMBER_TITLE = 'Betriebs-/Filialnummer';
export const SUBSIDIARY_MODAL_NUMBER_PLACEHOLDER = 'Betriebs-/Filialnummer';
export const SUBSIDIARY_MODAL_NUMBER_INVALID =
  'Das Feld "Betriebs-/Filialnummer" muss ausgefüllt werden.';
export const SUBSIDIARY_MODAL_POSTCODE_TITLE = 'Postleitzahl';
export const SUBSIDIARY_MODAL_POSTCODE_PLACEHOLDER = 'Postleitzahl';
export const SUBSIDIARY_MODAL_POSTCODE_INVALID =
  'Das Feld "Postleitzahl" muss ausgefüllt werden.';
export const SUBSIDIARY_MODAL_CITY_TITLE = 'Ort';
export const SUBSIDIARY_MODAL_CITY_PLACEHOLDER = 'Ort';
export const SUBSIDIARY_MODAL_CITY_INVALID =
  'Das Feld "Ort" muss ausgefüllt werden.';
export const SUBSIDIARY_MODAL_HOUSENUMBER_TITLE = 'Hausnummer';
export const SUBSIDIARY_MODAL_HOUSENUMBER_PLACEHOLDER = 'Hausnummer';
export const SUBSIDIARY_MODAL_HOUSENUMBER_INVALID =
  'Das Feld "Hausnummer" muss ausgefüllt werden.';
export const SUBSIDIARY_MODAL_STREET_TITLE = 'Straße';
export const SUBSIDIARY_MODAL_STREET_PLACEHOLDER = 'Straße';
export const SUBSIDIARY_MODAL_STREET_INVALID =
  'Das Feld "Straße" muss ausgefüllt werden.';
export const SUBSIDIARY_MODAL_COLOR = 'Filialfarbe';
export const SUBSIDIARY_MODAL_VERIFY_ADDRESS = 'Verorten';
export const SUBSIDIARY_MODAL_VERIFY_ADDRESS_TITLE = 'Bitte Adresse auswählen:';
export const SUBSIDIARY_MODAL_ADDRESS_LOCATE_INVALID_NO_SELECTION =
  'Es muss eine Adresse ausgewählt werden.';
export const SUBSIDIARY_MODAL_ADDRESS_LOCATE_INVALID_NOT_LOCATED =
  'Die angegebene Adresse muss verortet werden.';

export const PDF_COPYRIGHT =
  '(c) Agentur Frey GmbH & Co. KG - powered by OpenStreetMaps';
export const PDF_DATE = 'Datum';

export const CONFIRMATION_MODAL_TITLE_DELETE_SUBSIDIARY = 'Filiale löschen';
export const CONFIRMATION_MODAL_CONTENT_DELETE_SUBSIDIARY =
  'Wollen Sie diese Filiale wirklich löschen?';
export const CONFIRMATION_MODAL_TITLE_DELETE_DISTRIBUTION_TEMPLATE =
  'Verteilvorlage löschen';
export const CONFIRMATION_MODAL_CONTENT_DELETE_DISTRIBUTION_TEMPLATE =
  'Wollen Sie diese Verteilvorlage wirklich löschen?';
export const CONFIRMATION_MODAL_TITLE_REMOVE_ALL_AREAS =
  'Gesamte Auwahl löschen?';
export const CONFIRMATION_MODAL_CONTENT_REMOVE_ALL_AREAS =
  'Wollen Sie die gesamte Auswhal wirklich löschen?';

export const RESPONSE_MODAL_FAILURE_TITLE = 'Anfrage fehlgeschlagen';
export const RESPONSE_MODAL_FAILURE_CONTENT = (errorCode?: number): string =>
  `Ihre Anfrage ist Fehlgeschlagen. Bitte überprüfen Sie ihre Eingaben und versuchen Sie es erneut. ${
    errorCode ? `(Fehlercode: ${errorCode})` : ''
  }`;
export const RESPONSE_MODAL_SUCCESS_TITLE = 'Anfrage erfolgreich';
export const RESPONSE_MODAL_SUCCESS_CONTENT =
  'Ihre Anfrage wurde erfolgreich versendet.';

export const TOOLTIP_AREA_SHOW_LOCALITIES = 'Ortsteile anzeigen';
export const TOOLTIP_AREA_REMOVE = 'Gebiet entfernen';

export const TOOLTIP_DISTIRBUTION_TEMPLATE_REMOVE = 'Vorlage entfernen';

export const TOOLTIP_SELECTED_SUBSIDIARY_REMOVE =
  'Filiale aus Auswahl entfernen';
export const TOOLTIP_SELECTED_SUBSIDIARY_HIDE = 'Filialeauswahl ausblenden';

export const TOOLTIP_SUBSIDIARY_REMOVE = 'Filiale entfernen';
export const TOOLTIP_SUBSIDIARY_SHOW = 'Gehe zu Filiale';
export const TOOLTIP_SUBSIDIARY_EDIT = 'Filiale bearbeiten';
export const TOOLTIP_SUBSIDIARY_CHANGE_COLOR = 'Filialfarbe ändern';
export const TOOLTIP_SUBSIDIARY_DISTRIBUTION_TEMPLATES_SHOW =
  'Filial-Verteilvorlagen anzeigen';
export const TOOLTIP_SUBSIDIARY_DISTRIBUTION_TEMPLATES_SAVE =
  'Aktuelle Auswahl für Filiale speichern';
export const TOOLTIP_SUBSIDIARY_COLLAPSE_ALL = 'Alle Filialen einklappen';
export const TOOLTIP_SUBSIDIARY_EXPAND_ALL = 'Alle Filialen ausklappen';
export const TOOLTIP_SUBSIDIARY_REMOVE_ALL = 'Ganze Auswahl entfernen';

export const WARNING_MESSAGE_TITLE_NO_SUBSIDIARY_SELECTED =
  'Keine Filiale ausgewählt';
export const WARNING_MESSAGE_CONTENT_NO_SUBSIDIARY_SELECTED =
  'Bitte wählen Sie eine Filiale aus um Gebiete auszuwählen.';
export const WARNING_MESSAGE_TITLE_EXPORT_NO_SELECTION =
  'Keine Gebiete ausgewählt';
export const WARNING_MESSAGE_CONTENT_EXPORT_NO_SELECTION =
  'Bitte wählen Sie mindestens ein Gebiet aus um einen Export zu erstellen.';

export const NO_DATE_SET = 'Kein Datum vorhanden.';

export const TOOLTIP_DISTRIBUTION_TYPE = (
  distributionType: DistributionType,
  distributionName?: string
): string =>
  `${
    distributionType === 'DIRECT' ? 'Direktverteilung' : 'Beilagenverteilung'
  }${
    distributionName && distributionType === 'INSERT'
      ? ` (${distributionName})`
      : ''
  }`;

export const PRICE_SUM_EXCL_VATS_LABEL = (
  price?: string,
  pricePerThousand?: string
): string => {
  const zeroString = (0.0).toLocaleString('en-de', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${price ?? zeroString} ${PRICE_CURRENCY_SHORT} (${pricePerThousand ??
    zeroString}/${(1000).toLocaleString()} Stk.) (exkl. MwSt.)`;
};

export const PRICE_SUM_LABEL = (price?: Price): string => {
  const zeroString = (0.0).toLocaleString('en-de', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${price?.total ??
    zeroString} ${PRICE_CURRENCY_SHORT} (${price?.totalPerThousand ??
    zeroString}/${(1000).toLocaleString()} Stk.)`;
};
