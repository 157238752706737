import * as React from 'react';

import { ListGroup } from 'react-bootstrap';

import SubsidiaryDistributionTemplateListItem from './SubsidiaryDistributionTemplateListItem';

import { SUBSIDIARY_DISTRIBUTION_TEMPLATE_LIST_EMPTY } from '../../../constants/labels';

import { sortSubsidiaryDistributionTemplates } from '../../../util/sortUtil';

import { SubsidiaryDistributionTemplate } from '../../../@types/Common.d';
import { SubsidiaryDistributionTemplateListProps } from '../../../@types/Modal.d';

/**
 * Component to show a list of subsidiary distribution templates
 *
 * @param props
 */
const SubsidiaryDistributionTemplateList: React.FC<SubsidiaryDistributionTemplateListProps> = (
  props: SubsidiaryDistributionTemplateListProps
) => {
  const {
    subsidiaryDistributionTemplates,
    selectedSubsidiaryDistributionTemplate,
    selectSubsidiaryDistributionTemplate,
    removeSubsidiaryDistributionTemplate,
    subsidiary,
  } = props;

  return (
    <ListGroup className="h-100">
      {subsidiaryDistributionTemplates.length > 0 ? (
        sortSubsidiaryDistributionTemplates(
          subsidiaryDistributionTemplates
        ).map((template: SubsidiaryDistributionTemplate) => (
          <SubsidiaryDistributionTemplateListItem
            key={template.id}
            subsidiaryDistributionTemplate={template}
            selected={
              selectedSubsidiaryDistributionTemplate?.id === template.id
            }
            subsidiary={subsidiary}
            selectSubsidiaryDistributionTemplate={
              selectSubsidiaryDistributionTemplate
            }
            removeSubsidiaryDistributionTemplate={
              removeSubsidiaryDistributionTemplate
            }
          />
        ))
      ) : (
        <ListGroup.Item>
          {SUBSIDIARY_DISTRIBUTION_TEMPLATE_LIST_EMPTY}
        </ListGroup.Item>
      )}
    </ListGroup>
  );
};

export default SubsidiaryDistributionTemplateList;
