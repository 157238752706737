/**
 * This file holds a number of methods which are used to
 * convert area related data from the api endpoints to
 * a format which can be used in the application.
 *
 */

import { CIRCULATION_NO_DISTRIBUTION } from '../../constants/labels';
// eslint-disable-next-line import/no-cycle
import {
  calculateAreaCirculation,
  getAreaStub,
  calculateAreaCirculationTotal,
  priceString,
} from '../areaUtil';

import { Area, Locality } from '../../@types/Area.d';
import { PriceResult, LocationPrice, TotalPrice } from '../../@types/Common.d';

/**
 * Extract a single locality from a response.
 *
 * @param responseLocality
 */
export const extractLocality = (responseLocality: any): Locality => {
  const {
    areaKey,
    administrativeDistrict,
    id,
    information,
    locality,
    localityKey,
    municipality,
    postcode,
    selectable,
  } = responseLocality;

  let {
    circulation,
    circulationWeekend,
    circulationMidweek,
  } = responseLocality;

  if (circulation <= 0) {
    if (circulation === 0) circulation = CIRCULATION_NO_DISTRIBUTION;
    else circulation = information ?? CIRCULATION_NO_DISTRIBUTION;
  }
  if (circulationWeekend <= 0) {
    if (circulationWeekend === 0)
      circulationWeekend = CIRCULATION_NO_DISTRIBUTION;
    else circulationWeekend = information ?? CIRCULATION_NO_DISTRIBUTION;
  }
  if (circulationMidweek <= 0) {
    if (circulationMidweek === 0)
      circulationMidweek = CIRCULATION_NO_DISTRIBUTION;
    else circulationMidweek = information ?? CIRCULATION_NO_DISTRIBUTION;
  }

  return {
    administrativeDistrict,
    areaKey,
    ...(responseLocality.circulation !== undefined
      ? { circulation }
      : {
          circulation: {
            circulationWeekend,
            circulationMidweek,
          },
        }),
    id,
    locality,
    localityKey,
    municipality,
    postcode,
    selectable,
    selected: true,
  } as Locality;
};

/**
 * Extract an array of localities from a respone.
 *
 * @param responseLocalities
 */
export const extractLocalities = (responseLocalities: any): Locality[] =>
  (responseLocalities
    ?.map((responseLocality: any) => extractLocality(responseLocality))
    .sort((a: Locality, b: Locality) => {
      if (a.locality.toLocaleLowerCase() > b.locality.toLocaleLowerCase())
        return 1;
      if (a.locality.toLocaleLowerCase() < b.locality.toLocaleLowerCase())
        return -1;
      return 0;
    }) as Locality[]) ?? ([] as Locality[]);

/**
 * Extract a single area from a response.
 *
 * @param responseArea
 */
export const extractArea = (responseArea: any): Area => {
  const {
    additionalAreas,
    areaKey,
    areaName,
    coverage,
    id,
    information,
    localities,
    kindOfDistribution,
    distributorTitle,
    distributorLogo,
    mainOrganisation,
    weekpart,
  } = responseArea;

  let { circulation, circulationWeekend, circulationMidweek } = responseArea;

  const pLocalities = extractLocalities(localities) as Locality[];
  // eslint-disable-next-line no-use-before-define
  const pAdditionalAreas = extractAreas(additionalAreas);

  const areaStub = getAreaStub(areaKey, weekpart);
  areaStub.localities = pLocalities;
  areaStub.additionalAreas = pAdditionalAreas;

  if (circulation <= 0) {
    if (circulation === 0) circulation = CIRCULATION_NO_DISTRIBUTION;
    else circulation = information ?? CIRCULATION_NO_DISTRIBUTION;
  } else {
    circulation = calculateAreaCirculation(areaStub);
  }

  if (circulationWeekend <= 0) {
    if (circulationWeekend === 0)
      circulationWeekend = CIRCULATION_NO_DISTRIBUTION;
    else circulationWeekend = information ?? CIRCULATION_NO_DISTRIBUTION;
  }
  if (circulationMidweek <= 0) {
    if (circulationMidweek === 0)
      circulationMidweek = CIRCULATION_NO_DISTRIBUTION;
    else circulationMidweek = information ?? CIRCULATION_NO_DISTRIBUTION;
  }

  const circulationTotal = calculateAreaCirculationTotal(areaStub);

  return {
    // eslint-disable-next-line no-use-before-define
    additionalAreas: pAdditionalAreas,
    areaKey,
    areaName,
    distributionType: kindOfDistribution,
    distributorTitle,
    distributorLogo,
    mainOrganisation,
    ...(responseArea.circulation !== undefined
      ? { circulation }
      : {
          circulation: {
            circulationWeekend,
            circulationMidweek,
          },
        }),
    circulationTotal,
    coverage,
    id,
    localities: pLocalities,
    weekpart,
  } as Area;
};

/**
 * Extract an array of areas from a response.
 *
 * @param responseAreas
 */
export const extractAreas = (responseAreas: any): Area[] =>
  responseAreas?.map((responseArea: any) => extractArea(responseArea)) ??
  ([] as Area[]);

/**
 * Extract the total price of a subsidiary
 * and the price per tousand flyers.
 *
 * @param responsePrice
 */
export const extractLocationPrice = (responsePrice: any): LocationPrice => {
  const {
    id,
    totalDistribution,
    totalDistributionInclusiveVat,
    vat,
    totalDistributionPerThousand,
    totalKaufDa,
    circulation,
  } = responsePrice;

  return {
    id,
    total: priceString(totalDistribution),
    totalInclusiveVat: priceString(totalDistributionInclusiveVat),
    vat: priceString(vat),
    totalPerThousand: priceString(totalDistributionPerThousand),
    totalKaufDa: priceString(totalKaufDa),
    circulation: +circulation,
  } as LocationPrice;
};

/**
 * Extract the total price of the selection as well
 * as the single sub total and the price per tousand flyers.
 *
 * @param responsePrice
 */
export const extractTotalPrice = (responsePrice: any): TotalPrice => {
  const {
    id,
    total,
    totalInclusiveVat,
    vat,
    totalPerThousand,
    subtotalDistributionPerThousand,
    subtotalDistribution,
    subtotalKaufDa,
    subtotalPrint,
    subtotalPrintPerThousand,
  } = responsePrice;

  return {
    id,
    total: priceString(total),
    totalInclusiveVat: priceString(totalInclusiveVat),
    vat: priceString(vat),
    totalPerThousand: priceString(totalPerThousand),
    subtotalDistributionPerThousand: priceString(
      subtotalDistributionPerThousand
    ),
    subtotalDistribution: priceString(subtotalDistribution),
    subtotalKaufDa: priceString(subtotalKaufDa),
    subtotalPrint: priceString(subtotalPrint),
    subtotalPrintPerThousand: priceString(subtotalPrintPerThousand),
  } as TotalPrice;
};

/**
 * Extract the total price of the selection as well as the price per subsidiary
 * and the price per tousand flyers.
 *
 * @param responsePrice
 */
export const extractPrices = (responsePrices: any): PriceResult => {
  const {
    total,
    totalInclusiveVat,
    vat,
    locations,
    totalPerThousand,
    subtotalDistribution,
    subtotalDistributionPerThousand,
    subtotalKaufDa,
    subtotalPrint,
    subtotalPrintPerThousand,
  } = responsePrices;

  return {
    price: extractTotalPrice({
      total,
      totalInclusiveVat,
      vat,
      totalPerThousand,
      subtotalDistribution,
      subtotalDistributionPerThousand,
      subtotalKaufDa,
      subtotalPrint,
      subtotalPrintPerThousand,
    }),
    subsidiaryPrices: locations.map((location: any) =>
      extractLocationPrice(location)
    ),
  };
};
