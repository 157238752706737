import * as React from 'react';

import { Modal, Col, Button, Form, ListGroup, Spinner } from 'react-bootstrap';
import { RGBColor } from 'react-color';
import { cloneDeep } from 'lodash';

import ColorPicker from './ColorPicker';

import {
  rgbObjectTorgb,
  rgbTorgbObject,
  rgbTorgba,
} from '../../util/featureStyle';
import {
  MODAL_TITLE_EDIT_SUBSIDIARY,
  MODAL_TITLE_ADD_SUBSIDIARY,
  BUTTON_TITLE_ACCEPT,
  BUTTON_TITLE_ABORT,
  SUBSIDIARY_MODAL_NAME_TITLE,
  SUBSIDIARY_MODAL_NAME_PLACEHOLDER,
  SUBSIDIARY_MODAL_NAME_INVALID,
  SUBSIDIARY_MODAL_POSTCODE_TITLE,
  SUBSIDIARY_MODAL_POSTCODE_PLACEHOLDER,
  SUBSIDIARY_MODAL_POSTCODE_INVALID,
  SUBSIDIARY_MODAL_NUMBER_TITLE,
  SUBSIDIARY_MODAL_NUMBER_PLACEHOLDER,
  SUBSIDIARY_MODAL_NUMBER_INVALID,
  SUBSIDIARY_MODAL_CITY_TITLE,
  SUBSIDIARY_MODAL_CITY_PLACEHOLDER,
  SUBSIDIARY_MODAL_CITY_INVALID,
  SUBSIDIARY_MODAL_STREET_TITLE,
  SUBSIDIARY_MODAL_STREET_PLACEHOLDER,
  SUBSIDIARY_MODAL_STREET_INVALID,
  SUBSIDIARY_MODAL_HOUSENUMBER_TITLE,
  SUBSIDIARY_MODAL_HOUSENUMBER_PLACEHOLDER,
  SUBSIDIARY_MODAL_HOUSENUMBER_INVALID,
  SUBSIDIARY_MODAL_ADDRESS_NAME_TITLE,
  SUBSIDIARY_MODAL_ADDRESS_NAME_PLACEHOLDER,
  SUBSIDIARY_MODAL_ADDRESS_NAME_INVALID,
  SUBSIDIARY_MODAL_VERIFY_ADDRESS,
  SUBSIDIARY_MODAL_VERIFY_ADDRESS_TITLE,
  SUBSIDIARY_MODAL_COLOR,
  SUBSIDIARY_MODAL_ADDRESS_LOCATE_INVALID_NO_SELECTION,
  SUBSIDIARY_MODAL_ADDRESS_LOCATE_INVALID_NOT_LOCATED,
} from '../../constants/labels';
import { searchAddress } from '../../util/api';

import { ClientLocation } from '../../@types/Common.d';
import { Area } from '../../@types/Area.d';
import {
  ClientLocatoinModalProps,
  ClientLocatoinModalState,
  Address,
} from '../../@types/Modal.d';

/**
 * Modal dialog to update and create subsidiaries
 */
export default class ClientLocationModal extends React.Component<
  ClientLocatoinModalProps,
  ClientLocatoinModalState
> {
  constructor(props: ClientLocatoinModalProps) {
    super(props);

    this.state = {
      id: -1,
      name: '',
      addressName: '',
      number: '',
      postcode: '',
      city: '',
      housenumber: '',
      street: '',
      colorSelectedFill: 'rgba(0, 0, 150, 1.0)',
      coordinates: { lat: -1, lon: -1 },
      showPicker: false,
      addressLoading: false,
      validated: false,
    };

    this.hideModal = this.hideModal.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeColor = this.onChangeColor.bind(this);
    this.onChangeHousenumber = this.onChangeHousenumber.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeAddressName = this.onChangeAddressName.bind(this);
    this.onChangeNumber = this.onChangeNumber.bind(this);
    this.onChangePostCode = this.onChangePostCode.bind(this);
    this.onChangeStreet = this.onChangeStreet.bind(this);
    this.onClickAbort = this.onClickAbort.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClickAddressSearch = this.onClickAddressSearch.bind(this);
    this.showColorPicker = this.showColorPicker.bind(this);
  }

  /**
   * Click action to submit create a new or update a subsidiary
   *
   * @param event
   */
  onClickSubmit(event: React.FormEvent<HTMLFormElement>): void {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState(
      {
        validated: true,
      },
      () => {
        if (formValid) {
          const {
            addressName,
            name,
            number,
            postcode,
            city,
            housenumber,
            street,
            colorSelectedFill,
            coordinates,
            clientLocation,
          } = this.state;
          const { updateSusidiary, newSubsidiary } = this.props;

          const subsidiary = {
            id: clientLocation ? clientLocation.id : -1,
            name,
            addressName,
            number,
            postcode,
            city,
            housenumber,
            street,
            colorSelectedFill: rgbTorgba(colorSelectedFill, 0.2),
            lat: coordinates.lat,
            lon: coordinates.lon,
            img: clientLocation ? clientLocation.img : '',
            selected: clientLocation ? clientLocation.selected : false,
            areas: clientLocation ? clientLocation.areas : ([] as Area[]),
          } as ClientLocation;

          if (clientLocation) updateSusidiary(subsidiary);
          else newSubsidiary(subsidiary);

          this.hideModal();
        }
      }
    );
  }

  /**
   * Click action to search for coordinates for the provided address or vice versa
   *
   * @param event
   */
  async onClickAddressSearch(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> {
    event.stopPropagation();

    this.setState({ addressLoading: true });
    const { postcode, city, housenumber, street } = this.state;
    const resultAddresses = (await searchAddress(
      `${postcode} ${city} ${street} ${housenumber}`
    )) as Address[];

    this.setState({ addresses: resultAddresses, addressLoading: false });
  }

  /**
   * Click action to select one of the received subsidiaries
   *
   * @param event
   * @param address
   */
  onClickAddressItem(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    address: Address
  ): void {
    event.stopPropagation();

    const { addresses } = this.state;

    if (!addresses) return;

    addresses.forEach(aAddress => {
      if (address.name === aAddress.name) aAddress.selected = true;
      else aAddress.selected = false;
    });

    this.setState({
      addresses: [...addresses],
      coordinates: address.coordinates,
    });
  }

  /**
   * Click action to abort the creation or update of the subsidiary
   *
   * @param event
   */
  onClickAbort(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    event.stopPropagation();

    this.hideModal();
  }

  /**
   * Change action for the subsidiaries name
   *
   * @param event
   */
  onChangeName(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();

    this.setState({
      name: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries address name
   *
   * @param event
   */
  onChangeAddressName(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();

    this.setState({
      addressName: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries company number
   *
   * @param event
   */
  onChangeNumber(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      number: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries postcode
   *
   * @param event
   */
  onChangePostCode(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      postcode: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries city
   *
   * @param event
   */
  onChangeCity(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      city: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries housenumber
   *
   * @param event
   */
  onChangeHousenumber(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      housenumber: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries street
   *
   * @param event
   */
  onChangeStreet(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      street: event.currentTarget.value ? event.currentTarget.value : '',
    });
  }

  /**
   * Change action for the subsidiaries selection color
   *
   * @param event
   */
  onChangeColor(color: RGBColor): void {
    this.setState({ colorSelectedFill: rgbObjectTorgb(color, 0.2) });
  }

  /**
   * Set the subsidary to be edited. If the subsidiary which is provided is undefined
   * this will be a modal dialog for a new subsdiary.
   *
   * @param clientLocation
   */
  setClientLocation(clientLocation: ClientLocation): void {
    const pClientLocation = cloneDeep(clientLocation);
    const {
      id,
      addressName,
      colorSelectedFill,
      name,
      number,
      postcode,
      city,
      housenumber,
      street,
      lat,
      lon,
    } = pClientLocation;

    const coordinates = { lat, lon };

    this.setState({
      id,
      addressName,
      clientLocation: pClientLocation,
      colorSelectedFill,
      name,
      number,
      postcode,
      city,
      housenumber,
      street,
      coordinates,
      addresses: undefined,
    });
  }

  /**
   * Method to show a color picker to choose a color
   * for the subsidiary.
   */
  showColorPicker(): void {
    const { showPicker } = this.state;
    this.setState({ showPicker: !showPicker });
  }

  /**
   * Method to hide the modal dialog and clear the state
   * of the prevoiusly submited data.
   */
  hideModal(): void {
    const { showModal } = this.props;

    this.setState(
      {
        id: -1,
        name: '',
        addressName: '',
        number: '',
        postcode: '',
        city: '',
        housenumber: '',
        street: '',
        colorSelectedFill: 'rgba(0, 0, 150, 1.0)',
        coordinates: { lat: -1, lon: -1 },
        showPicker: false,
        addressLoading: false,
        validated: false,
        addresses: undefined,
        clientLocation: undefined,
      },
      () => showModal(false)
    );
  }

  render(): JSX.Element {
    const { container, show } = this.props;
    const {
      addressName,
      id,
      addressLoading,
      addresses,
      clientLocation,
      colorSelectedFill,
      showPicker,
      name,
      coordinates,
      number,
      postcode,
      city,
      housenumber,
      street,
      validated,
    } = this.state;

    const coordCheckValue = coordinates.lat >= 0 ? `${coordinates.lat}` : '';

    return (
      <Modal
        show={show}
        onHide={this.hideModal}
        container={container.current}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {clientLocation
              ? MODAL_TITLE_EDIT_SUBSIDIARY
              : MODAL_TITLE_ADD_SUBSIDIARY}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            id="client-location-modal-form"
            className="client-location-modal-form"
            onSubmit={this.onClickSubmit}
            noValidate
            validated={validated}
          >
            <Form.Row>
              <Col>
                <Form.Group controlId="input-name">
                  <Form.Label>{SUBSIDIARY_MODAL_NAME_TITLE}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="organization"
                    onChange={this.onChangeName}
                    value={name}
                    placeholder={SUBSIDIARY_MODAL_NAME_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {SUBSIDIARY_MODAL_NAME_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="input-number">
                  <Form.Label>{SUBSIDIARY_MODAL_NUMBER_TITLE}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="new-password"
                    onChange={this.onChangeNumber}
                    value={number}
                    placeholder={SUBSIDIARY_MODAL_NUMBER_PLACEHOLDER}
                  />
                  <Form.Control.Feedback type="invalid">
                    {SUBSIDIARY_MODAL_NUMBER_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={8}>
                <Form.Group controlId="input-name">
                  <Form.Label>{SUBSIDIARY_MODAL_ADDRESS_NAME_TITLE}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="new-password"
                    onChange={this.onChangeAddressName}
                    value={addressName}
                    placeholder={SUBSIDIARY_MODAL_ADDRESS_NAME_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {SUBSIDIARY_MODAL_ADDRESS_NAME_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={4}>
                <Form.Group controlId="input-postcode">
                  <Form.Label>{SUBSIDIARY_MODAL_POSTCODE_TITLE}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="postal-code"
                    onChange={this.onChangePostCode}
                    value={postcode}
                    placeholder={SUBSIDIARY_MODAL_POSTCODE_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {SUBSIDIARY_MODAL_POSTCODE_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={8}>
                <Form.Group controlId="input-city">
                  <Form.Label>{SUBSIDIARY_MODAL_CITY_TITLE}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="address-level2"
                    onChange={this.onChangeCity}
                    value={city}
                    placeholder={SUBSIDIARY_MODAL_CITY_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {SUBSIDIARY_MODAL_CITY_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={8}>
                <Form.Group controlId="input-street">
                  <Form.Label>{SUBSIDIARY_MODAL_STREET_TITLE}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="address-line1"
                    onChange={this.onChangeStreet}
                    value={street}
                    placeholder={SUBSIDIARY_MODAL_STREET_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {SUBSIDIARY_MODAL_STREET_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="input-housenumber">
                  <Form.Label>{SUBSIDIARY_MODAL_HOUSENUMBER_TITLE}</Form.Label>
                  <Form.Control
                    as="input"
                    autoComplete="address-line2"
                    onChange={this.onChangeHousenumber}
                    value={housenumber}
                    placeholder={SUBSIDIARY_MODAL_HOUSENUMBER_PLACEHOLDER}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {SUBSIDIARY_MODAL_HOUSENUMBER_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col md={4}>
                <Form.Group controlId="color">
                  <Form.Label>{SUBSIDIARY_MODAL_COLOR}</Form.Label>
                  <Form.Control
                    as="div"
                    style={{
                      background: rgbTorgba(colorSelectedFill, 1),
                    }}
                    onClick={this.showColorPicker}
                  />
                  {showPicker && (
                    <ColorPicker
                      id={`${id}`}
                      color={rgbTorgbObject(colorSelectedFill, 1)}
                      accept={this.onChangeColor}
                      abort={this.showColorPicker}
                    />
                  )}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Button
                variant="primary"
                className="ci"
                onClick={this.onClickAddressSearch}
              >
                {addressLoading && (
                  <Spinner as="span" animation="border" size="sm" />
                )}
                {SUBSIDIARY_MODAL_VERIFY_ADDRESS}
              </Button>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Group>
                  {addresses && (
                    <div className="mt-1">
                      <Form.Label>
                        {SUBSIDIARY_MODAL_VERIFY_ADDRESS_TITLE}
                      </Form.Label>
                      <ListGroup className="w-100">
                        {addresses.map(address => (
                          <ListGroup.Item
                            className="hover-item selectable"
                            key={address.coordinates.lat}
                            onClick={(
                              event: React.MouseEvent<
                                HTMLAnchorElement,
                                MouseEvent
                              >
                            ) => this.onClickAddressItem(event, address)}
                            active={address.selected}
                          >
                            {address.name}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  )}
                  <Form.Control
                    value={coordCheckValue}
                    hidden
                    onChange={() => {}}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {addresses
                      ? SUBSIDIARY_MODAL_ADDRESS_LOCATE_INVALID_NO_SELECTION
                      : SUBSIDIARY_MODAL_ADDRESS_LOCATE_INVALID_NOT_LOCATED}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Col className="p-0">
            <Button
              type="submit"
              variant="primary"
              className="ci"
              form="client-location-modal-form"
            >
              {BUTTON_TITLE_ACCEPT}
            </Button>
          </Col>
          <Col className="p-0">
            <Button
              variant="primary"
              className="ci"
              onClick={this.onClickAbort}
            >
              {BUTTON_TITLE_ABORT}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
