import * as React from 'react';

import { Col, Row } from 'react-bootstrap';
import { faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomTooltip from '../common/CustomTooltip';

import {
  TOOLTIP_AREA_SHOW_LOCALITIES,
  TOOLTIP_AREA_REMOVE,
} from '../../constants/labels';

import { AreaIconProps } from '../../@types/AreaList.d';

/**
 * Component to display action buttons for an selected Area
 *
 * @param props
 */
const AreaIconContainer: React.FC<AreaIconProps> = (props: AreaIconProps) => {
  const { additionalArea, area, removeArea, showLocalities } = props;

  /**
   * Key press action to remove area from selection
   *
   * @param event
   */
  const onKeyPressRemove = (event: React.KeyboardEvent): void => {
    event.stopPropagation();
    if (event.keyCode !== 13) return;

    removeArea(area);
  };

  /**
   * Key press actoion to bring up localities modal
   *
   * @param event
   */
  const onKeyPressLocalities = (event: React.KeyboardEvent): void => {
    event.stopPropagation();
    if (event.keyCode !== 13) return;

    showLocalities(area, true);
  };

  /**
   * Click action to remove area from selection
   *
   * @param event
   */
  const onClickRemove = (event: React.MouseEvent): void => {
    event.stopPropagation();

    removeArea(area);
  };

  /**
   * Click action to bring up localities modal
   *
   * @param event
   */
  const onClickLocalities = (event: React.MouseEvent): void => {
    event.stopPropagation();

    showLocalities(area, true);
  };

  return (
    <Row className="no-gutters justify-content-end">
      <Col sm={6}>
        <CustomTooltip
          tooltipId={`area-${area.id}-loc`}
          content={TOOLTIP_AREA_SHOW_LOCALITIES}
        >
          <div
            role="button"
            className="icon-localities hover-icon tooltip-icon"
            tabIndex={0}
            onClick={onClickLocalities}
            onKeyUp={onKeyPressLocalities}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>
        </CustomTooltip>
      </Col>
      <Col sm={6}>
        {!additionalArea && (
          <CustomTooltip
            tooltipId={`area-${area.id}-rem`}
            content={TOOLTIP_AREA_REMOVE}
          >
            <div
              role="button"
              className="icon-remove hover-icon"
              tabIndex={0}
              onClick={onClickRemove}
              onKeyUp={onKeyPressRemove}
            >
              <FontAwesomeIcon icon={faTrash} />
            </div>
          </CustomTooltip>
        )}
      </Col>
    </Row>
  );
};

export default AreaIconContainer;
