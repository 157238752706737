import { Feature } from 'ol';
import { Icon, Style, Fill, Stroke, Text } from 'ol/style';
import { FeatureLike } from 'ol/Feature';
import { ColorLike } from 'ol/colorlike';
import CircleStyle from 'ol/style/Circle';
import { RGBColor } from 'react-color';

import {
  FEATURE_FIELD_AREA_KEY,
  PLZ_FONT,
  DEFAULT_FEATURE_COLOR_STYLE,
  DEFAULT_STROKE_WIDTH,
  DEFAULT_PERIMETER_STROKE_WIDTH,
  DEFAULT_PERIMETER_POINT_RADIUS,
  DEFAULT_PERIMETER_POINT_STROKE_COLOR,
  DEFAULT_PERIMETER_POINT_STROKE_WIDTH,
  LABEL_FONT_SCALE,
  LABEL_FONT_SCALE_PERIMETER,
  STYLE_ZINDEX_BACK,
} from '../constants/constants';

import { ColorStyle } from '../@types/Common.d';

/**
 * Convert a rgb to an rgba string
 *
 * @param colorRGB
 * @param opacity
 */
export const rgbTorgba = (colorRGB: string, opacity: number = 1): string => {
  // rgb[0] = r, rgb[1] = g, rgb[2] = b, rgb[3] = a (if present)
  const rgb = colorRGB.match(/\d+|(\.\d*)/g);
  if (rgb !== null) return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${opacity})`;
  return colorRGB;
};

/**
 * Convert a rgb or rgba string to an RGBColor object.
 *
 * @param colorRGB
 * @param opacity
 */
export const rgbTorgbObject = (
  colorRGB: string,
  opacity: number = 1
): RGBColor => {
  // rgb[0] = r, rgb[1] = g, rgb[2] = b, rgb[3] = a (if present)
  const rgb = colorRGB.match(/\d+|(\.\d*)/g);
  if (rgb !== null)
    return { r: +rgb[0], g: +rgb[1], b: +rgb[2], a: opacity } as RGBColor;
  return { r: 0, g: 0, b: 0, a: 0 } as RGBColor;
};

/**
 * Conert a RGBObject to a rgba string.
 *
 * @param colorObject
 * @param opacity
 */
export const rgbObjectTorgb = (
  colorObject: RGBColor,
  opacity?: number
): string =>
  // rgb[0] = r, rgb[1] = g, rgb[2] = b, rgb[3] = a (if present)
  `rgba(${colorObject.r},${colorObject.g},${colorObject.b},${opacity ||
    colorObject.a})`;

/**
 * Generate label that can be used with a feature
 *
 * @param name
 * @param fontSize
 */
const generateFeatureLabel = (name: string, fontSize?: number): Text =>
  new Text({
    text: name,
    font: PLZ_FONT,
    scale: fontSize ?? LABEL_FONT_SCALE,
    stroke: new Stroke({ color: 'rgba(255, 255, 255, 1)', width: 3 }),
  });

/**
 * Generate a selected or deselected style for a feature.
 *
 * @param selected
 * @param name
 * @param colors
 */
export const generateFeatureStyle = (
  selected: boolean,
  name: string,
  colors: ColorStyle = DEFAULT_FEATURE_COLOR_STYLE
): Style =>
  new Style({
    fill: new Fill({ color: selected ? colors.fillSelected : colors.fill }),
    stroke: new Stroke({
      color: selected ? colors.strokeSelected : colors.stroke,
      width: colors.strokeWidth ?? DEFAULT_STROKE_WIDTH,
    }),
    text: generateFeatureLabel(name),
    zIndex: colors.zIndex ?? STYLE_ZINDEX_BACK,
  });

/**
 * Generate a selected or deselected additional area
 * style for a feature.
 *
 * @param selected
 * @param name
 * @param colors
 */
export const generateAdditionalFeatureStyle = (
  selected: boolean,
  name: string,
  colors: ColorStyle = DEFAULT_FEATURE_COLOR_STYLE
): Style =>
  new Style({
    fill: new Fill({
      color: selected
        ? (color => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (context === null) return color;

            canvas.width = 10;
            canvas.height = 10;

            context.fillStyle = color;
            context.fillRect(0, 0, canvas.width, canvas.height);

            context.fillStyle = rgbTorgba(color as string, 0.23) as ColorLike;
            context.fillRect(5, 0, 5, 10);
            context.fill();

            const pattern = context.createPattern(canvas, 'repeat');
            if (pattern !== null) return pattern;
            return color;
          })(colors.fillSelected)
        : colors.fill,
    }),
    stroke: new Stroke({
      color: selected ? colors.strokeSelected : colors.stroke,
      width: colors.strokeWidth ?? DEFAULT_STROKE_WIDTH,
    }),
    text: generateFeatureLabel(name),
    zIndex: colors.zIndex ?? STYLE_ZINDEX_BACK,
  });

/**
 * Generate a selected or deselected multiselection
 * area style for a feature.
 *
 * @param selected
 * @param name
 * @param colors
 */
export const generateMultipleSelectionFeatureStyle = (
  selected: boolean,
  name: string,
  colors: ColorStyle[] = [DEFAULT_FEATURE_COLOR_STYLE]
): Style =>
  new Style({
    fill: new Fill({
      color: selected
        ? ((pColors: ColorStyle[]) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (context === null) return pColors[0].fillSelected;

            canvas.width = 5 * colors.length;
            canvas.height = 10;

            pColors.forEach((color: ColorStyle, index: number) => {
              context.fillStyle = rgbTorgba(
                color.fillSelected as string,
                0.3
              ) as ColorLike;
              context.fillRect(5 * index, 0, 5, 10);
            });

            context.fill();

            const pattern = context.createPattern(canvas, 'repeat');
            if (pattern !== null) return pattern;
            return pColors[0].fillSelected;
          })(colors)
        : colors[0].fill,
    }),
    stroke: new Stroke({
      color: selected ? colors[0].strokeSelected : colors[0].stroke,
      width: colors[0].strokeWidth ?? DEFAULT_STROKE_WIDTH,
    }),
    text: generateFeatureLabel(name),
    zIndex: colors[0].zIndex ?? STYLE_ZINDEX_BACK,
  });

/**
 * Wrapper method to generate a select style
 * for a given feature
 *
 * @param feature
 * @param colors
 */
export const setFeatureSelected = (
  feature: Feature,
  colors?: ColorStyle
): void =>
  feature.setStyle(
    generateFeatureStyle(true, feature.get(FEATURE_FIELD_AREA_KEY), colors)
  );

/**
 * Wrapper method to generate a deselected
 * style for a given feature
 *
 * @param feature
 * @param colors
 */
export const setFeatureNotSelected = (
  feature: Feature,
  colors?: ColorStyle
): void =>
  feature.setStyle(
    generateFeatureStyle(false, feature.get(FEATURE_FIELD_AREA_KEY), colors)
  );

/**
 * Wrapper method to generate a selected
 * multiselection stype for a given feature
 *
 * @param feature
 * @param colors
 */
export const setFeatureMultiSelected = (
  feature: Feature,
  colors?: ColorStyle[]
): void =>
  feature.setStyle(
    generateMultipleSelectionFeatureStyle(
      true,
      feature.get(FEATURE_FIELD_AREA_KEY),
      colors
    )
  );

/**
 * Wrapper method to generate a deselected
 * multiselection stype for a given feature
 *
 * @param feature
 * @param colors
 */
export const setFeatureMultiNotSelected = (
  feature: Feature,
  colors?: ColorStyle[]
): void =>
  feature.setStyle(
    generateMultipleSelectionFeatureStyle(
      false,
      feature.get(FEATURE_FIELD_AREA_KEY),
      colors
    )
  );

/**
 * Wrapper method to generate a selected
 * additional area stype for a given feature
 *
 * @param feature
 * @param colors
 */
export const setAdditionalFeatureSelected = (
  feature: Feature,
  colors?: ColorStyle
): void =>
  feature.setStyle(
    generateAdditionalFeatureStyle(
      true,
      feature.get(FEATURE_FIELD_AREA_KEY),
      colors
    )
  );

/**
 * Wrapper method to generate a deselected
 * additional area stype for a given feature
 *
 * @param feature
 * @param colors
 */
export const setAdditionalFeatureNotSelected = (
  feature: Feature,
  colors?: ColorStyle
): void =>
  feature.setStyle(
    generateAdditionalFeatureStyle(
      false,
      feature.get(FEATURE_FIELD_AREA_KEY),
      colors
    )
  );

/**
 * Generate a default style that can be used
 * as a style for a layer.
 *
 * @param feature
 * @param color
 */
export const generateBaseLayerStyle = (
  feature: FeatureLike,
  color: ColorStyle = DEFAULT_FEATURE_COLOR_STYLE
): Style =>
  new Style({
    stroke: new Stroke({
      color: color.stroke,
      width: color.strokeWidth ?? DEFAULT_STROKE_WIDTH,
    }),
    fill: new Fill({
      color: color.fill,
    }),
    text: generateFeatureLabel(feature.get(FEATURE_FIELD_AREA_KEY)),
  });

/**
 * Generate a style that can be used as a
 * style for a client location/ subsidiary.
 *
 * @param poiIcon
 */
export const generateLocationStyle = (poiIcon: string): Style =>
  new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: poiIcon,
    }),
  });

/**
 * Generate a styöe for a the circle of a perimeter selection
 *
 * @param distance
 */
export const generatePerimeterStyle = (distance?: string): Style[] => [
  new Style({
    fill: new Fill({ color: DEFAULT_FEATURE_COLOR_STYLE.fill }),
    stroke: new Stroke({
      color: DEFAULT_FEATURE_COLOR_STYLE.stroke,
      width: DEFAULT_PERIMETER_STROKE_WIDTH,
    }),
    text: generateFeatureLabel(
      distance ? `${distance} km` : '',
      LABEL_FONT_SCALE_PERIMETER
    ),
  }),
  new Style({
    image: new CircleStyle({
      radius: DEFAULT_PERIMETER_POINT_RADIUS,
      fill: new Fill({ color: DEFAULT_FEATURE_COLOR_STYLE.strokeSelected }),
      stroke: new Stroke({
        color: DEFAULT_PERIMETER_POINT_STROKE_COLOR,
        width: DEFAULT_PERIMETER_POINT_STROKE_WIDTH,
      }),
    }),
  }),
];
