/* eslint-disable no-self-compare */
import * as React from 'react';

import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelopeOpenText,
  faNewspaper,
} from '@fortawesome/free-solid-svg-icons';

import CustomTooltip from './CustomTooltip';

import {
  CIRCULATION_PIECES_SHORT,
  CIRCULATION_TITLE_MIDWEEK_LONG,
  CIRCULATION_TITLE_WEEKEND_LONG,
  CIRCULATION_TITLE_BEST_LONG,
  CIRCULATION_TITLE_MIDWEEK_SHORT,
  CIRCULATION_TITLE_WEEKEND_SHORT,
  TOOLTIP_DISTRIBUTION_TYPE,
} from '../../constants/labels';
import { WEEKPART_WEEKEND, WEEKPART_BEST } from '../../constants/constants';

import { CirculationItemProps } from '../../@types/App.d';

/**
 * Component to display the circulation number of the current selection.
 * Can be used for the total circulation of the whole selection or for
 * the seperate subsidiaries/areas.
 *
 * @param props
 */
const CirculationItem: React.FC<CirculationItemProps> = (
  props: CirculationItemProps
) => {
  const {
    areaKey,
    areaWeekpart,
    weekpart,
    circulation,
    totalCirculation,
    distributionTitle,
    distributionType,
  } = props;

  // Determines if the current circulation is included in another area (e.g. additional areas)
  const included =
    Number.isNaN(+circulation) || typeof circulation === 'string';

  // The long version of the current weekpart to display alongside the circulation (only total circulation)
  let weekpartLong = CIRCULATION_TITLE_MIDWEEK_LONG;
  // The short version of the current weekpart to display alongside the circulation (only weekpart BEST)
  const weekpartShort =
    areaWeekpart === WEEKPART_WEEKEND
      ? CIRCULATION_TITLE_WEEKEND_SHORT
      : CIRCULATION_TITLE_MIDWEEK_SHORT;

  if (weekpart === WEEKPART_WEEKEND)
    weekpartLong = CIRCULATION_TITLE_WEEKEND_LONG;
  else if (weekpart === WEEKPART_BEST)
    weekpartLong = CIRCULATION_TITLE_BEST_LONG;

  return (
    <Row
      className={`no-gutters ${totalCirculation ? 'total-circulation' : ''}`}
    >
      <Col sm={12} className="p-0">
        <Row className="no-gutters">
          {totalCirculation && (
            <Col sm={6} className="p-0">
              {weekpartLong}
            </Col>
          )}
          <Col sm={totalCirculation ? 6 : 11} className="text-right p-0">
            <Row className="no-gutters">
              <Col className="p-0 custom-text-wrap">
                {!included
                  ? `${circulation.toLocaleString()} ${CIRCULATION_PIECES_SHORT} ${
                      areaWeekpart &&
                      weekpart === WEEKPART_BEST &&
                      !totalCirculation
                        ? `(${weekpartShort})`
                        : ''
                    }`
                  : circulation}
              </Col>
              {distributionType && (
                <Col xs={3} className="p-0">
                  <CustomTooltip
                    tooltipId={`area-distriution-type-${areaKey}-tooltip`}
                    content={TOOLTIP_DISTRIBUTION_TYPE(
                      distributionType,
                      distributionTitle
                    )}
                  >
                    <FontAwesomeIcon
                      className="pl-1 tooltip-icon"
                      icon={
                        distributionType === 'DIRECT'
                          ? faEnvelopeOpenText
                          : faNewspaper
                      }
                    />
                  </CustomTooltip>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CirculationItem;
