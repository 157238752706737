import * as React from 'react';

import { ListGroup, Row, Col } from 'react-bootstrap';

import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomTooltip from '../common/CustomTooltip';

import { TOOLTIP_DISTIRBUTION_TEMPLATE_REMOVE } from '../../constants/labels';

import { DistributionTemplateListItemPorps } from '../../@types/DistributionTemplateList.d';

/**
 * Component to display a distribution template item inside the DistributionTemplateList
 *
 * @param props
 */
const DistributionTemplateListItem: React.FC<DistributionTemplateListItemPorps> = (
  props: DistributionTemplateListItemPorps
) => {
  const {
    distributionTemplate,
    planningRestriction,
    applyDistributionTemplate,
    deleteDistributionTemplate,
  } = props;

  /**
   * Click action for selecting a distribution template and show it on the map.
   *
   * @param event
   */
  const onClickItem = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    applyDistributionTemplate(distributionTemplate);
  };

  /**
   * Click action for deleteing a distribution template.
   *
   * @param event
   */
  const onClickDelete = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.stopPropagation();

    deleteDistributionTemplate(distributionTemplate);
  };

  /**
   * Key down action for deleteing a distribution template.
   *
   * @param event
   */
  const onKeyDownDelete = (
    event: React.KeyboardEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();
    if (event.keyCode === 13) deleteDistributionTemplate(distributionTemplate);
  };

  return (
    <ListGroup.Item onClick={onClickItem} className="hover-item">
      <Row className="no-gutters">
        <Col>{distributionTemplate.name}</Col>
        {planningRestriction === 'NONE' && (
          <Col xs={2} className="text-right">
            <CustomTooltip
              tooltipId={`template-${distributionTemplate.id}-rem`}
              content={TOOLTIP_DISTIRBUTION_TEMPLATE_REMOVE}
              placement="left-start"
            >
              <div
                role="button"
                tabIndex={0}
                onClick={onClickDelete}
                onKeyDown={onKeyDownDelete}
                className="hover-icon"
              >
                <FontAwesomeIcon icon={faTrash} />
              </div>
            </CustomTooltip>
          </Col>
        )}
      </Row>
    </ListGroup.Item>
  );
};

export default DistributionTemplateListItem;
