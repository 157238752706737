import * as React from 'react';

import { ListGroup } from 'react-bootstrap';

import SubsidiaryAreaListItem from './SubsidiaryAreaListItem';

import { sortClientLocations } from '../../util/sortUtil';

import { SubsidiaryAreaListProps } from '../../@types/AreaList.d';

/**
 * Component to show a list of subsidiaries with its selected areas
 *
 * @param props
 */
const SubsidiaryAreaList: React.FC<SubsidiaryAreaListProps> = (
  props: SubsidiaryAreaListProps
) => {
  const {
    showAll,
    subsidiaries,
    weekpart,
    removeArea,
    removeSubsidiary,
    showLocalities,
    hideSubsidiarySelection,
  } = props;

  return (
    <ListGroup className="h-100 selected-subsidiary-list">
      {sortClientLocations(subsidiaries).map(subsidiary => (
        <SubsidiaryAreaListItem
          key={subsidiary.id}
          show={showAll}
          subsidiary={subsidiary}
          removeArea={removeArea}
          removeSubsidiary={removeSubsidiary}
          showLocalities={showLocalities}
          weekpart={weekpart}
          hideSubsidiarySelection={hideSubsidiarySelection}
        />
      ))}
    </ListGroup>
  );
};

export default SubsidiaryAreaList;
