import * as React from 'react';

import { Row, Modal, Button, Col, Form } from 'react-bootstrap';

import {
  BUTTON_TITLE_CREATE,
  BUTTON_TITLE_ABORT,
  MODAL_TITLE_ADD_DISTRIBUTION_TEMPLATE,
  DISTRIBUTION_TEMPLATE_MODAL_NAME_TITLE,
  DISTRIBUTION_TEMPLATE_MODAL_NAME_PLACEHOLDER,
  DISTRIBUTION_TEMPLATE_MODAL_NAME_INVALID,
} from '../../constants/labels';

import {
  DistributionTemplateModalProps,
  DistributionTemplateModalState,
} from '../../@types/Modal.d';
import { ClientLocation } from '../../@types/Common.d';

/**
 * Modal template to create a new distribution template from the current selection
 * or the current selection of a specific subsidiary.
 */
export default class DistributionTemplateModal extends React.Component<
  DistributionTemplateModalProps,
  DistributionTemplateModalState
> {
  constructor(props: DistributionTemplateModalProps) {
    super(props);
    this.state = {
      name: '',
      validated: false,
    };

    this.onChangeName = this.onChangeName.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onHide = this.onHide.bind(this);
    this.setSubsidiary = this.setSubsidiary.bind(this);
  }

  /**
   * Change action for the distribution templates name.
   *
   * @param event
   */
  onChangeName(event: React.ChangeEvent<HTMLInputElement>): void {
    event.stopPropagation();
    this.setState({
      name: event.currentTarget.value,
    });
  }

  /**
   * Click action to submit the new distribution template.
   *
   * @param event
   */
  onClickSubmit(event: React.FormEvent<HTMLFormElement>): void {
    const formValid = event.currentTarget.checkValidity();

    event.preventDefault();
    event.stopPropagation();

    this.setState({ validated: true }, () => {
      if (formValid) {
        const { newDistributionTemplate } = this.props;
        const { name, subsidiary } = this.state;

        newDistributionTemplate(name, subsidiary);

        this.onHide();
      }
    });
  }

  /**
   * Hide and reset the modal dialog.
   */
  onHide(): void {
    const { showModal } = this.props;

    this.setState({
      name: '',
      validated: false,
      subsidiary: undefined,
    });

    showModal(false);
  }

  /**
   * Set the subsidiary if this is a subsidiary distribution template.
   *
   * @param subsidiary
   */
  setSubsidiary(subsidiary?: ClientLocation): void {
    this.setState({ subsidiary });
  }

  render(): JSX.Element {
    const { show, container } = this.props;
    const { validated } = this.state;

    return (
      <Modal
        show={show}
        onHide={this.onHide}
        container={container.current}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{MODAL_TITLE_ADD_DISTRIBUTION_TEMPLATE}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            id="distribution-template-modal-form"
            className="distribution-template-modal-form"
            onSubmit={this.onClickSubmit}
            noValidate
            validated={validated}
          >
            <Row>
              <Col>
                <Form.Group controlId="input-number">
                  <Form.Label>
                    {DISTRIBUTION_TEMPLATE_MODAL_NAME_TITLE}
                  </Form.Label>
                  <Form.Control
                    as="input"
                    placeholder={DISTRIBUTION_TEMPLATE_MODAL_NAME_PLACEHOLDER}
                    required
                    onChange={this.onChangeName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {DISTRIBUTION_TEMPLATE_MODAL_NAME_INVALID}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Col className="p-0">
            <Button
              type="submit"
              variant="primary"
              className="ci"
              form="distribution-template-modal-form"
            >
              {BUTTON_TITLE_CREATE}
            </Button>
          </Col>
          <Col className="p-0">
            <Button variant="primary" className="ci" onClick={this.onHide}>
              {BUTTON_TITLE_ABORT}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    );
  }
}
